q_style.writeStyle("qrf_progress",'.qrfprogress', {
    position:"absolute",
    top:"0",
    left:"0",
    width:"100%",
    "height":"100%",
    "z-index": "1",
    "background":"url(./module/common/images/progress/progress-store@2x.jpg)",
    "background-size":"100% 100%",
    ".qrfprogress_bg":{
        position:"absolute",top:"74%",left:"calc(50% - 542px)",width:"1084px",height:"33px","border-radius": "5px","z-index":"20","border":"solid 1px",
        img:{position:"absolute",width:"100%",height:"100%",},
    },
    ".progress_finish":{
        position:"absolute",top:"calc(74% + 1px)",left:"calc(50% - 542px)",height:"31px","border-radius": "5px","z-index":"22",
        img:{position:"absolute",width:"100%",height:"100%",},
    },
    ".progress_rider":{
        position:"absolute",top:"calc(74% - 224px)",width:"228px",height:"214px","z-index":"24",
        img:{position:"absolute",width:"100%",height:"100%",},
    }
}).apply("qrf_progress");