var qrfDom = new q_DOM("q_progress");
require("./css/indexCss");

qrfDom.addVars({
    progressW:10,
    riderLeft:542,
    progressShow:false,
});
let progressAW = 1084;
qrfDom.addEves({
    riding:({speed,callback})=>{/*speed 小于100 大于0*/
        var dw = progressAW / 100 * speed;
        qrfDom.set("progressW",10);
        qrfDom.set("riderLeft",542);
        qrfDom.set("progressShow",true);
        var ctrl = setInterval(function(){
            var tw = qrfDom.compute("progressW+"+dw);
            var rl = qrfDom.compute("riderLeft-"+dw);
            // console.log("tw:"+tw);
            if(tw >= progressAW){
                tw = progressAW;
            }
            qrfDom.set("progressW",parseInt(tw));
            qrfDom.set("riderLeft",parseInt(rl));
            if(tw >= progressAW){
                qrfDom.set("progressShow",false);
                clearInterval(ctrl);
                if(typeof callback){
                    callback();
                }
            }
        },100);
    }
});

qrfDom.definedComp("progress",`
<div class="qrfprogress" qrf-if="progressShow">
<div class="qrfprogress_bg"><img src="./module/common/images/progress/progress-bg.png"/></div>
<div class="progress_finish" qrf-style="'width:'+progressW+'px;'"><img src="./module/common/images/progress/progress-finish.png"/></div>
<div class="progress_rider" qrf-style="'left:calc(50% - '+riderLeft+'px)'"><img src="./module/common/images/progress/rider.png"/></div>
</div>
`);


if(!qrfDom.public)qrfDom.public={};
if(!qrfDom.public.eves)qrfDom.public.eves={};
qrfDom.public.eves.riding=qrfDom.eves.riding;

var html = `
<div><comp-progress></comp-progress></div>`;
qrfDom.render(html);
